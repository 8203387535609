<template>
  <Loading v-if="isInitLoading" />
  <div
    v-else
    style="height: 100vh"
    :style="{ overflow: show ? 'hidden' : 'auto' }"
  >
    <Header callBack @click="goBack">
      {{ anyGetGoodmarketInfoByid.goods.name }}
    </Header>

    <div class="details_box">
      <img :src="anyGetGoodmarketInfoByid.goods.showPic" alt="" />
      <div class="details_text">{{ anyGetGoodmarketInfoByid.goods.name }}</div>
    </div>
    <!-- 阴影 -->
    <div class="shadow"></div>

    <div class="message">
      <!-- <div class="message_collection">所属专辑</div> -->
      <div class="message_name">
        <div class="name_box">
          <div class="name_message">商品名称</div>
          <div class="name_num">{{ anyGetGoodmarketInfoByid.goodsNumber }}</div>
        </div>
        <div class="name_box" @click="serial">
          <div class="name_all">查看全部</div>
          <!-- <img src="../../../static/image/right.png" alt="" /> -->
          <div class="right"></div>
        </div>
      </div>

      <div class="issue_box">
        <div class="issue">
          <span class="issue_text">发行</span>
          <span class="issue_num"
            >{{ anyGetGoodmarketInfoByid.goods.stockNum }}份</span
          >
        </div>
        <div class="issue">
          <span class="issue_text">流通</span>
          <span class="issue_num"
            >{{ anyGetGoodmarketInfoByid.goods.circulationNum }} 份</span
          >
        </div>
      </div>
    </div>
    <div class="shadow"></div>

    <div class="message">
      <!-- <div class="message_collection">链上信息</div>
      <div class="message_name">
        <div class="name_box">
          <div class="mark_text">合约地址</div>
          <div class="mark_num">
            nfk...dsjfhdskfh
            <imgserial
              class="mark_img"
              src="../../../static/image/fuzhi@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="message_name">
        <div class="name_box">
          <div class="mark_text">链上标示</div>
          <div class="mark_num">78535857</div>
        </div>
      </div> -->
    </div>
    <div class="shadow"></div>

    <div style="padding: 20px 20px 0">
      <!-- 拥有者 -->
      <div class="possess">
        <div v-if="craftsmanInfo.user.nickName">
          <div class="possess_name">
            {{ craftsmanInfo.user.nickName }} <span>创作者/版权方</span>
          </div>
          <div class="possess_img">
            <span>{{ craftsmanInfo.address }}</span>
            <img
              @click="copy(craftsmanInfo.address)"
              src="../../../static/image/fuzhi@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="possess" v-for="(item, key) in findOwner1" :key="item.id">
        <!-- <img src="../../../static/image/ren_05.jpg" alt="" /> -->
        <div style="position: relative">
          <div class="possess_name">
            {{ item.user.nickName }}
            <span class="owner" v-if="key === 0">拥有者</span>
          </div>
          <div class="possess_img" :class="key !== 0 ? 'between' : null">
            <span>{{ item.address }}</span>
            <img
              @click="copy(item.address)"
              src="../../../static/image/fuzhi@2x.png"
              alt=""
            />
          </div>
          <div v-if="owner.length > 1" @click="isOpen = !isOpen">
            <i class="open" v-if="isOpen"></i>
            <img
              v-else
              class="openImg"
              src="../../../static/image/dian.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isOpen" style="padding: 0 20px; box-sizing: border-box">
      <div class="possess" v-for="item in findOwner" :key="item.id">
        <!-- <img src="../../../static/image/ren_05.jpg" alt="" /> -->
        <div style="position: relative">
          <div class="possess_name">
            {{ item.user.nickName }}
          </div>
          <div class="possess_img between">
            <span>{{ item.address }}</span>
            <img
              @click="copy(item.address)"
              src="../../../static/image/fuzhi@2x.png"
              alt=""
            />
            <span class="time">{{ item.pay_time }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="shadow"></div>
    <div class="anyGetGoodmarketInfoByidDescribe" v-html="tupian"></div>

    <div class="abridged">
      <div v-for="(linle, index) in this.temp" :key="index">
        <img :src="linle.url" alt="" />
      </div>
    </div>
    <div class="niuniu"></div>

    <div class="bottom">
      <div class="bottom_num">
        <span class="num_mon">￥</span
        ><span class="num_seven">{{ anyGetGoodmarketInfoByid.fee }}</span>
      </div>
      <div
        class="bottom_but"
        :class="anyGetGoodmarketInfoByid.state !== 1 ? 'lock' : null"
        @click="immediately(anyGetGoodmarketInfoByid)"
      >
        {{ anyGetGoodmarketInfoByid.state !== 1 ? '已锁定' : '立即购买' }}
      </div>
    </div>

    <div class="commodityList" v-if="show">
      <div class="wrapper">
        <div class="box" @click="show = false"></div>
        <div class="block">
          <div class="product">
            <div>商品列表</div>
            <div @click="dei">
              <img src="../../../static/image/close.png" alt="" />
            </div>
          </div>
          <div class="checkedbox">
            <!-- <van-checkbox @change="backcheck" v-model="checked" icon-size="14px"
              >仅看寄售商品</van-checkbox
            > -->
            <div class="van-checkbox" @click="backcheck">
              <i class="radio" :class="checked ? 'active' : null"></i>
              <span>仅看寄售商品</span>
            </div>
            <div class="checkbox">
              <div class="checkBoxChild" @click="sortList('goodsNumber')">
                编号
                <div>
                  <i
                    class="just"
                    :class="
                      keyName === 'goodsNumber' && sort === 'desc'
                        ? 'active'
                        : null
                    "
                  ></i
                  ><i
                    class="inverted"
                    :class="
                      keyName === 'goodsNumber' && sort === 'asc'
                        ? 'active'
                        : null
                    "
                  ></i>
                </div>
              </div>
              <div class="checkBoxChild" @click="sortList('fee')">
                价格
                <div>
                  <i
                    class="just"
                    :class="
                      keyName === 'fee' && sort === 'desc' ? 'active' : null
                    "
                  ></i
                  ><i
                    class="inverted"
                    :class="
                      keyName === 'fee' && sort === 'asc' ? 'active' : null
                    "
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="noData" v-if="!anyGetPositionListByGoodsid.length">
            <img src="../../../static/image/emptyBox.png" alt="" />
            <div>暂无数据</div>
          </div>
          <!-- <div style="overflow: auto" ref="scroll"> -->
          <van-list
            v-else
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            loading-text="数据加载中..."
            style="overflow: auto"
            @load="onLoad"
            :immediate-check="false"
          >
            <div
              class="product_list"
              v-for="(list, index) in anyGetPositionListByGoodsid"
              :key="index"
              @click="numbering(list)"
            >
              <div class="product_num">
                <div class="product_name">{{ list.goodsNumber }}</div>

                <div
                  class="num_several"
                  :class="
                    list.status == 1 && list.state == 1
                      ? 'active'
                      : list.status != 1
                      ? 'unActive'
                      : 'lock'
                  "
                >
                  <span>
                    {{
                      list.status == 1 && list.state == 1
                        ? '寄卖中'
                        : list.status != 1
                        ? '未寄卖'
                        : '锁定中'
                    }}
                  </span>
                </div>
              </div>
              <div class="product_text">
                ￥{{ list.fee }}
                <img
                  class="product_img"
                  src="../../../static/image/right.png"
                  alt=""
                />
              </div>
            </div>
            <!-- <div class="even" @click="nextPage" v-if="isShowMore">
              查看更多
              <img src="../../../static/image/bottom.png" alt="" />
            </div> -->
          </van-list>
        </div>
      </div>
    </div>
    <!-- <div class="test">
      <div class="content">
        <p v-for="item in 30" :key="item">{{ item }}</p>
      </div>
    </div> -->

    <!-- 开启盲盒-----遮罩层 -->
    <van-overlay :show="mystery">
      <div class="mystery_nei">
        <div class="mystery_block">
          <div class="mystery_text">您有相同的订单还未支付, 快去支付吧!</div>
          <div class="mystery_but">
            <div class="mystery_one" @click="mystery = false">取消</div>
            <div
              class="mystery_tow"
              @click="$router.replace(`/unpaid?id=${goodsId}&orderType=2`)"
            >
              去支付
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <div
      v-if="isToHome && !$route.query.platform"
      class="openInApp"
      @click="openApp"
    >
      <div class="img">
        <img src="../../../static/image/logo1.png" alt="" />
      </div>
      <span>APP内打开</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: true, //查看仅寄卖
      id: '', //传来的id
      anyGetGoodmarketInfoByid: { goods: {} }, //商场商品详情列表
      goid: '',
      good: '',
      show: false,
      anyGetPositionListByGoodsid: [],
      tupian: '', //下面的说明
      numid: '', // 选择编号进行购买
      temp: '',
      craftsmanInfo: { user: {} },
      owner: [],
      pageindex: 1,
      isShowMore: false,
      isInitLoading: true,
      keyName: 'fee',
      sort: 'asc',
      goodsId: undefined,
      mystery: false,
      isOpen: false,
      scroll: null,
      finished: false,
      loading: false,
      isToHome: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === '/') {
        vm.isToHome = true;
      } else {
        vm.isToHome = false;
      }
    });
  },
  watch: {
    show(val) {
      if (!val) {
        this.removeEventListener();
      }
    },
  },
  computed: {
    findOwner() {
      const [_, ...res] = this.owner;
      return res;
    },
    findOwner1() {
      if (this.owner && this.owner.length) {
        return [this.owner[0]];
      } else {
        return [];
      }
    },
  },
  methods: {
    openApp() {
      window.open('https://v.hxnft.shop/appDownload/index.html');
    },
    goBack() {
      if (this.isToHome) {
        this.$router.replace('/home');
      } else {
        this.$router.back();
      }
    },
    onLoad() {
      if (this.isShowMore) {
        this.pageindex++;
        this.mai();
      } else {
        this.finished = true;
      }
    },
    onScroll(e) {
      const clientHeight = e.target.clientHeight;
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (clientHeight + scrollTop >= scrollHeight) {
        console.log('到底了');
        if (this.isShowMore) {
          this.pageindex++;
          this.mai();
        }
      }
    },
    removeEventListener() {
      if (this.$refs.scroll) {
        this.$refs.scroll.removeEventListener('scroll', this.onScroll);
      }
    },
    //  查看下一页
    nextPage() {
      this.pageindex++;
      this.mai();
    },
    numbering(item) {
      if (item.status !== 1) return this.$toast('该编号暂无寄卖');
      this.initial(item.id);
      this.getAnyGoodsUserhaveList(item.id);
      this.show = false;
      // this.checked = false;
    },

    // 选择
    backcheck() {
      this.checked = !this.checked;
      this.anyGetPositionListByGoodsid = [];
      this.pageindex = 1;
      this.finished = false;
      this.isShowMore = true;
      if (this.checked == true) {
        this.mai();
      } else {
        this.mai();
      }
    },
    sortList(key) {
      this.pageindex = 1;
      this.keyName = key;
      this.finished = false;
      this.sort = this.sort === 'desc' ? 'asc' : 'desc';
      this.isShowMore = true;
      this.mai();
    },
    dei() {
      this.show = false;
      // this.checked = false;
    },
    // 查看全部编号
    serial() {
      this.show = true;
      this.pageindex = 1;
      this.isShowMore = true;
      this.finished = false;
      this.mai();
      this.$nextTick(() => {
        const scroll = this.$refs.scroll;
        if (scroll) {
          scroll.addEventListener('scroll', this.onScroll, true);
        }
      });
    },
    // 寄卖状态
    mai() {
      let params = {
        token: localStorage.getItem('token'),
        pageindex: this.pageindex,
        pagesize: 20,
        id: this.good,
        isallow: Number(this.checked),
        keyname: this.keyName,
        sort: this.sort,
      };
      this.$api.anyGetPositionListByGoodsid(params).then(
        (res) => {
          this.loading = false;
          if (res.code == 0) {
            if (res.data.length < 20) {
              this.isShowMore = false;
            } else {
              this.isShowMore = true;
            }
            if (this.pageindex === 1) {
              this.anyGetPositionListByGoodsid = res.data;
            } else {
              this.anyGetPositionListByGoodsid =
                this.anyGetPositionListByGoodsid.concat(res.data);
            }
          } else {
            this.$toast(res.msg);
          }
        },
        () => {
          this.loading = false;
        },
      );
    },
    // 立即购买
    immediately(item) {
      // let id = this.numid;
      // this.initial(id);
      if (item.state !== 1) return;
      if (!localStorage.getItem('token')) return this.$router.push('/login');
      // 确认订单
      let reg = {
        id: item.id,
        token: localStorage.getItem('token'),
      };
      this.$api.goodsorderDetail(reg).then(
        (res) => {
          this.isInitLoading = false;
          if (res.code == 100000) {
            const item = res.data;
            this.$router.push({
              path: '/OrderTow',
              query: { id: item.id, item: JSON.stringify(item[0]) },
            });
          } else if (res.code == 10002) {
            this.mystery = true;
            this.goodsId = res.id;
            // this.$router.replace(`/unpaid?id=${e.id}&orderType=2`);
          } else {
            this.$toast(res.msg);
          }
        },
        () => {
          this.isInitLoading = false;
        },
      );
    },

    // 初始时间刷新页面
    initial(id) {
      let params = {
        id: id,
      };
      this.$api.anyGetGoodmarketInfoByid(params).then(
        (res) => {
          this.isInitLoading = false;
          if (res.code == 0) {
            this.anyGetGoodmarketInfoByid = res.data;
            if (!this.anyGetGoodmarketInfoByid.goods) {
              this.anyGetGoodmarketInfoByid.goods = {};
            }
            this.good = res.data.goods.id;
            this.tupian = res.data.goods.describe;
            // let a = this.tupian;
            // var b = /<img[^>]+src=['"]([^'"]+)['"]+/g;
            // let result = [];
            // let temp = [];
            // while ((temp = b.exec(a)) != null) {
            //   result.push({ url: temp[1] });
            // }
            // this.temp = result;
          } else {
            this.$toast(res.msg);
          }
        },
        () => {
          this.isInitLoading = false;
        },
      );
    },
    // 获取商品拥有者
    getAnyGoodsUserhaveList(id) {
      const params = {
        id,
        type: 0,
      };
      this.$api.anyGoodsUserhaveList(params).then((res) => {
        if (res.code == 0) {
          this.craftsmanInfo = res.craftsmanInfo || { user: {} };
          const list = (res.data || []).filter(
            (e) => e.user.id !== this.craftsmanInfo.user.id,
          );
          list.sort(
            (a, b) =>
              new Date(b.pay_time).getTime() - new Date(a.pay_time).getTime(),
          );
          this.owner = (list || []).splice(0, 4);
        }
      });
    },
    copy(url) {
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      //复制完成删除掉输入框
      oInput.remove();
      this.$toast('复制成功');
    },
  },

  mounted() {
    this.isInitLoading = true;
    this.id = this.$route.query.id;
    // 市场详情----全部编号
    this.initial(this.$route.query.entrustId);
    this.getAnyGoodsUserhaveList(this.$route.query.entrustId);
  },
  beforeDestroy() {
    this.removeEventListener();
  },
};
</script>
<style lang="less" scoped>
.niuniu {
  height: 50px;
}
/deep/.van-checkbox {
  display: flex;
  align-items: center;
  padding-top: 10px;
  font-size: 15px;
  height: 20px;
  margin-left: 14px;
}
/deep/.van-checkbox__label {
  color: #999999;
  font-size: 12px;
}
.van-checkbox {
  display: flex;
  align-items: center;
  padding-top: 10px;
  font-size: 13px;
  height: 20px;
  color: #cecece;
  margin-left: 14px;
  &:hover {
    color: #000000;
  }
  .radio {
    width: 13px;
    height: 13px;
    border: 1px solid #e78e10;
    border-radius: 3px;
    margin-right: 5px;
    display: block;
    position: relative;
    &.active {
      &::before {
        content: '';
        display: block;
        transform: rotate(315deg);
        width: 80%;
        height: 5px;
        border-bottom: 1px solid #e78e10;
        border-left: 1px solid #e78e10;
        position: absolute;
        bottom: 5px;
      }
    }
  }
}
.checkedbox {
  align-items: center;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
}
.checkbox {
  display: flex;
  padding-right: 10px;
  div.checkBoxChild {
    margin: 0 10px;
    display: flex;
    align-items: center;
    color: #cecece;
    &:hover {
      color: #000000;
    }
    i {
      display: flex;
      flex-direction: column;
      margin: 3px 0;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      &.just {
        &::after {
          display: block;
          content: '';
          border-top: 5px solid transparent;
          border-bottom: 5px solid #ddd;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
        &.active {
          &.just {
            &::after {
              border-bottom: 5px solid #333;
            }
          }
        }
      }
      &.inverted {
        &::after {
          display: block;
          content: '';
          border-top: 5px solid #ddd;
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
        &.active {
          &.inverted {
            &::after {
              border-top: 5px solid #333;
            }
          }
        }
      }
    }
  }
}
.even {
  font-size: 12px;
  color: #abaab9;
  background-color: #abaab957;
  width: 70px;
  text-align: center;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-left: 15px;
  img {
    width: 6px;
    height: 4px;
  }
}
.product_list {
  display: flex;
  font-size: 16px;
  line-height: 60px;
  justify-content: space-between;
  margin-right: 20px;
  .product_text {
    display: flex;
    align-items: center;
  }
  .product_num {
    margin-left: 10px;
    display: flex;
    font-size: 12px;
    flex: 1;
    min-width: 1px;
  }
  .product_name {
    white-space: nowrap;
    min-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .num_several {
    margin-left: 5px;
    font-size: 10px;
    color: #aaa;
    white-space: nowrap;
    &.active {
      color: #e78e10;
      span {
        padding: 3px;
        background: #fffbf8;
      }
    }
    &.unActive {
      color: #cecece;
      span {
        background: #f8f8f8;
        padding: 3px;
      }
    }
    &.lock {
      color: #009944;
      span {
        background: #deffee;
        padding: 3px;
      }
    }
  }
  .product_img {
    width: 4px;
    height: 6px;
    margin-left: 5px;
  }
}
.product {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  img {
    width: 12px;
    height: 12px;
  }
}
.commodityList {
  z-index: 99;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  .box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}
.wrapper {
  display: flex;
  // align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 70%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.details_box {
  width: 100vw;
  img {
    width: 375px;
    // height: 254px;
    object-fit: cover;
    display: inline-block;
  }
  .details_text {
    font-size: 18px;
    font-weight: 700;
    line-height: 62px;
    margin-left: 10px;
  }
}

// 阴影
.shadow {
  background-color: #f1f1f1;
  height: 10px;
}
.message {
  margin-left: 16px;
}
.message_collection {
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 15px;
}
.message_name {
  display: flex;
  justify-content: space-between;
  .name_box {
    display: flex;
    align-items: center;
    font-size: 14px;
    .name_message {
      font-size: 14px;
      font-size: 15px;
      line-height: 50px;
    }
    .name_num {
      font-size: 16px;
      color: black;
      font-weight: 600;
      margin-left: 10px;
      max-width: 180px;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      text-align: left;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .name_all {
      font-size: 14px;
      color: #0754d3;
    }
    .right {
      width: 6px;
      height: 6px;
      display: block;
      position: relative;
      border-top: 2px solid #0754d3;
      border-right: 2px solid #0754d3;
      transform: rotate(45deg);
      margin-right: 20px;
    }
    img {
      width: 6px;
      height: 10px;
      display: inline-block;
      margin-right: 16px;
      margin-left: 7px;
    }
  }
}

.issue_box {
  display: flex;
  .issue {
    .issue_text {
      background-color: #abaab9;
      font-size: 12px;
      color: #ffffff;
      display: inline-block;
      height: 20px;
      width: 34px;
      text-align: center;
      line-height: 20px;
    }

    padding-bottom: 25px;
    .issue_num {
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      display: inline-block;
      background-color: #f3f4f8;
      padding-left: 5px;
      padding-right: 5px;
      color: #4c4b58;
      margin-right: 10px;
    }
  }
}

.mark_text {
  font-size: 16px;
  color: #999999;
}
.mark_num {
  margin-left: 16px;
  font-size: 14px;
  color: #000000;
  .mark_img {
    width: 13px;
    height: 13px;
    display: inline-block;
    // background-color: black;
  }
}

.possess {
  padding-bottom: 20px;
  img {
    display: block;
    width: 36px;
    height: 36px;
    padding-right: 15px;
  }
  .possess_name {
    font-size: 12px;
    font-weight: 600;
    span {
      color: #0754d3;
      font-size: 12px;
      font-style: italic;
      margin-left: 7px;
    }
    .owner {
      color: #ffaf09;
    }
  }
  .possess_img {
    display: flex;
    align-items: center;
    font-size: 14px;
    &.between {
      .time {
        font-size: 12px;
        text-align: right;
        flex: 1;
      }
    }
    span {
      display: block;
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #abaab9;
    }
    img {
      display: block;
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
  }
  .open {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #aaa;
    border-right: 2px solid #aaa;
    transition: all 0.3s;
  }
  .openImg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    padding: 0;
  }
}

.author {
  text-align: center;
  width: 342px;
  padding-bottom: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  margin-left: 18px;
  margin-bottom: 18px;
  .author_intro {
    font-size: 18px;
    font-weight: 600;
    line-height: 64px;
  }
  img {
    width: 137px;
    height: 172px;
  }
  .author_img {
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
  }
  .author_text {
    margin-left: 44px;
    margin-right: 44px;
    font-size: 16px;
  }
}
.abridged {
  // width: 100%;
  padding-bottom: 20px;
  object-fit: cover;
  img {
    width: 100%;
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  // padding-right: 16px;
  .num_mon {
    font-size: 10px;
    padding-left: 16px;
  }
  .num_seven {
    font-size: 20px;
  }
  .bottom_but {
    margin-right: 16px;
    width: 166px;
    height: 40px;
    background-color: #0754d3;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    border-radius: 10px;
    color: #ffffff;
    &.lock {
      background-color: #aaa;
    }
  }
}
.test {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  .content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70%;
    background: #fff;
    overflow: auto;
  }
}
.mystery_nei {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .mystery_block {
    background-color: #fff;
    width: 250px;
    height: 150px;
    border-radius: 10px;
    .mystery_text {
      font-size: 16px;
      text-align: center;
      margin: 20px auto 0;
      width: 80%;
    }
    .mystery_but {
      display: flex;
      font-size: 14px;
      justify-content: space-around;
      margin-top: 20px;
      .mystery_tow {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        background-color: black;
        color: #ffffff;
      }
      .mystery_one {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        // background-color: ;
        border: 1px black solid;
      }
    }
  }
}
.noData {
  text-align: center;
  margin-top: 10px;
  img {
    width: 100px;
    margin-right: -10px;
  }
  div {
    font-size: 12px;
    color: #666;
  }
}
.openInApp {
  position: fixed;
  right: 0;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  .img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
  span {
    font-size: 12px;
  }
}
</style>

<style>
.anyGetGoodmarketInfoByidDescribe {
  padding: 20px;
  font-size: 16px;
}
.anyGetGoodmarketInfoByidDescribe img {
  max-width: 100%;
}
</style>
